import React from "react";

export default function InjuryStatus({ status, side, detail }: { status: string, side?: "left" | "right", detail?: boolean }) {
  if (side === "right") {
    return (
      <div className="flex gap-3 items-center">
        <div className={`w-[10px] h-[10px] ${status === "Doubtful" ? "bg-[#FFA709]" : status === "Out" ? "bg-error-300" : "bg-secondary"} rounded-full`}></div>
        {detail ? status : ""}
      </div>
    );

  }
  return (
    <div className="flex items-center justify-end text-right gap-4">
      {detail ? status : ""}
        <div className={`w-[10px] h-[10px] ${status === "Doubtful" ? "bg-[#FFA709]" : status === "Out" ? "bg-error-300" : "bg-secondary"} rounded-full`}></div>
      </div>
  )
}
